<template>
  <el-form ref="form" label-width="70px">
    <div class="wrap" id="wrap" v-loading="loading">
      <!-- 顶部按钮 -->
      <topOperatingButton
        :isSubmitAddBtn="false"
        :isAuditBillBtn="false"
        @submitForm="submitForm"
        @getQuit="getQuit"
        id="topOperatingButton"
      />
      <cardTitleCom cardTitle="查询" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <div class="marB10">
              <span class="fS14MR10">单据类型</span>
              <el-checkbox
                v-model="isProduce"
                class="checkOrder"
                :disabled="true"
                >生产计划单</el-checkbox
              >
            </div>
            <!-- 高级搜索按钮 -->
            <seniorSearch
              filterTimeStr="thisMonth"
              v-model="queryParams"
              :getList="getList"
              :isInvokeList="false"
              @isShowHighCom="getIsShowHigh"
              :refreshSearch="refreshQueryParams"
              :class="{ marB10: !showHigh }"
            />
            <!-- 第二行 -->
            <div v-show="showHigh" class="x-w marT10">
              <el-form-item label="生产组" prop="produceGroupIds">
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.produceGroupIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listProduceGroup',
                      option: {
                        option: {
                          remoteBody: {
                            produceDeptId: queryParams.produceDeptId
                          }
                        }
                      }
                    }).option
                  "
                />
              </el-form-item>
              <el-form-item label="生产班次" prop="produceOrderIds">
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.produceOrderIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listProduceOrder',
                      option: {
                        option: {
                          remoteBody: {
                            produceDeptId: queryParams.produceDeptId
                          }
                        }
                      }
                    }).option
                  "
                />
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="选择源单">
        <template slot="cardContent">
          <div class="tableContent">
            <el-table
              v-loading="loadingTable"
              tooltip-effect="dark"
              :data="tableData"
              border
              ref="multipleTable"
              @row-click="handleRowClick"
              :max-height="tableHeight"
              :height="tableHeight"
            >
              <el-table-column width="55" align="center">
                <template v-slot="scope">
                  <el-radio v-model="billItem" :label="scope.row">
                    {{ '' }}
                  </el-radio>
                </template>
              </el-table-column>
              <el-table-column
                label="序号"
                align="center"
                width="80"
                type="index"
              />
              <el-table-column
                show-overflow-tooltip
                label="单据编号"
                align="center"
                prop="billNo"
                width="180"
              />
              <el-table-column
                label="单据日期"
                align="center"
                prop="billDate"
                width="110"
              />
              <el-table-column
                label="生产工厂"
                align="center"
                prop="produceDeptName"
                width="120"
              />
              <el-table-column
                label="生产组"
                align="center"
                prop="produceGroupName"
                width="120"
              />
              <el-table-column
                label="生产班次"
                align="center"
                prop="produceOrderName"
                width="120"
              />
              <el-table-column
                label="单据状态"
                align="center"
                prop="billStatusName"
              />
              <el-table-column
                label="领料状态"
                align="center"
                prop="leadMaterialStatusName"
              />
              <el-table-column
                label="完工状态"
                align="center"
                prop="finishWorkStatusName"
              />
              <el-table-column
                label="质检状态"
                align="center"
                prop="qualityCheckStatusName"
              />
              <el-table-column
                label="入库状态"
                align="center"
                prop="inStoreStatusName"
              />
              <el-table-column
                label="生产日期"
                align="center"
                prop="produceDate"
              />
              <el-table-column label="备注" align="center" prop="billRemark" />
            </el-table>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :totalCom="total"
              :isSelectAll="false"
            />
          </div>
        </template>
      </cardTitleCom>
    </div>
  </el-form>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import bottomPage from '@/views/components/bottomPage' //底部分页
import { planListAPI, findDetailWithBomAPI } from '@/api/produce/bill/plan' //生产计划单
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
export default {
  name: 'leadMaterialImport',
  dicts: ['bill_status'],
  components: {
    bottomPage,
    cardTitleCom,
    seniorSearch,
    SelectRemote,
    topOperatingButton
  },
  data () {
    return {
      //单据类型
      isProduce: true,
      loading: false,
      loadingTable: false,
      showHigh: false, //是否显示高级搜索
      billItem: undefined, //选中的数据  生产计划单
      total: 0, // 总条数
      tableData: [], //表格数据
      //重置查询参数
      refreshQueryParams: {
        pageNum: 1,
        pageSize: 15,
        billStatus: '2',
        leadMaterialStatus: '1',
        isImport: 1,
        finishWorkStatus: 1, //完工状态(1未完工、2已完工)
        billType: 170202
      },
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        billStatus: '2',
        leadMaterialStatus: '1',
        isImport: 1,
        finishWorkStatus: 1,
        billType: 170202
      }, //查询
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      topOperatingH: 0, //顶部按钮高度
      paginationH: 40
    }
  },
  computed: {
    tableHeight () {
      return (
        this.vivwH - this.topOperatingH - this.searchH - this.paginationH - 62
      )
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.topOperatingH =
        document.getElementById('topOperatingButton').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      // 访问组件实例 vm 中的数据和方法
      if (
        vm.$route.query.type === 'leadMaterialImport' &&
        vm.$route.query?.produceDeptId
      ) {
        vm.refreshQueryParams.produceDeptId = vm.$route.query.produceDeptId
        vm.queryParams.produceDeptId = vm.refreshQueryParams.produceDeptId
        await vm.getList()
      }
    })
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.topOperatingH =
        document.getElementById('topOperatingButton').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.billItem = row
      // this.$refs.multipleTable.toggleRowSelection(row)
    },
    //确定
    async submitForm () {
      if (this.billItem?.billId) {
        const { data } = await findDetailWithBomAPI(this.billItem.billId)
        data.forEach(v => {
          this.$set(v, 'unitPrice', v.unitPurPrice)
          this.$set(v, 'billNo', this.billItem.billNo)
          this.$set(v, 'sourceBillId', this.billItem.billId)
        })
        console.log('rows', data)
        this.$store.state.tagsView.visitedViews.splice(
          this.$store.state.tagsView.visitedViews.findIndex(
            item => item.path === this.$route.path
          ),
          1
        )
        this.$destroy()
        this.$router.push({
          name: 'leadMaterialDetail',
          query: {
            sourceBillNo: this.billItem.billNo,
            sourceGoodsList: JSON.stringify(data),
            produceDeptId: this.queryParams.produceDeptId,
            type: 'importLeadMaterial'
          }
        })
      } else {
        this.$store.state.tagsView.visitedViews.splice(
          this.$store.state.tagsView.visitedViews.findIndex(
            item => item.path === this.$route.path
          ),
          1
        )
        this.$destroy()
        this.$router.push({
          name: 'leadMaterialDetail'
        })
      }
    },
    //取消
    getQuit () {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$router.push('/produce/bill/backMaterial/backMaterialDetail')
      this.$destroy()
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await planListAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    //是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 0px 20px;
    //筛选单据
    .checkOrder {
      margin-right: 50px;
    }
  }
  //源单信息表格
  .tableContent {
    margin: 6px 10px 10px 10px;
  }
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
</style>
