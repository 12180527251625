<template>
  <el-form ref="form" :model="leadMaterialForm" label-width="96px" :rules="rules">
    <div class="wrap" id="wrap" v-loading="loading">
      <topOperatingButton :isImportOrderBtn="showImportBill" :disabled="disabled" :showAudit="showAudit"
        :isAddBtn="showAdd" @submitForm="submitForm" @addBill="addBill" @auditBill="auditBill" @handleAdd="handleAdd"
        @getQuit="getQuit" @getImportOrder="getImportOrder" id="topOperatingButton" />
      <!-- 基本信息盒子 -->
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="基本信息" :billStatus="leadMaterialForm.billStatus" id="basicInformation">
        <template slot="cardContent">
          <!-- 商品信息 -->
          <div class="x-w marT10">
            <!-- 第一行 -->
            <el-form-item label="单据编号" prop="billNo">
              <el-input class="inputWidth" :disabled="true" v-model="leadMaterialForm.billNo" placeholder="单据编号"
                size="mini"></el-input>
            </el-form-item>

            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker style="width: 170px" v-model="leadMaterialForm.billDate" type="date" placeholder="单据日期"
                :disabled="disabled" value-format="yyyy-MM-dd" size="mini">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="生产计划单" prop="sourceBillNo">
              <el-input class="inputWidth" :disabled="true" v-model="leadMaterialForm.sourceBillNo" placeholder="生产计划单"
                size="mini"></el-input>
            </el-form-item>
            <el-form-item label="领料工厂" prop="produceDeptId">
              <SelectRemote @selectChange="changeProduceGroupChange" v-model="leadMaterialForm.produceDeptId"
                style="width: 250px" :option="
                  $select({
                    key: 'listProduceDept',
                    option: {
                      option: {
                        disabled: disabled || isImportSource,
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="部门" prop="deptId">
              <SelectLocal v-model="leadMaterialForm.deptId" style="width: 250px" :option="
                  $select({
                    key: 'listSimpleQuery',
                    option: {
                      option: {
                        disabled: disabled,
                        showItem: [
                          {
                            deptId: leadMaterialForm.deptId,
                            deptName: leadMaterialForm.deptName,
                          },
                        ],
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="备注" prop="billRemark">
              <el-input class="inputRemark" :disabled="disabled" v-model="leadMaterialForm.billRemark" type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 5 }" size="mini" />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
        <template slot="rightCardTitle">
          <div class="marR10 x-f">
            <el-button :disabled="disabled || !leadMaterialForm.produceDeptId" type="primary" size="mini"
              @click="getDistribution(1)" class="marL10">批量修改仓库</el-button>
          </div>
        </template>
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <el-table ref="multipleTable" :data="leadMaterialForm.details" border show-summary
              :summary-method="getSummaries" @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave"
              :max-height="tableHeight" :height="tableHeight" @selection-change="handleSelectionChange"
              @row-click="handleRowClick" v-loading="loadingTable">
              <el-table-column type="selection" width="60" align="center" :selectable="() => !disabled" />
              <el-table-column align="center" :label="tableCellLabel" width="80">
                <template v-slot="scope">
                  <i v-if="scope.row.hoverRow" :disabled="disabled" @click.stop="row('push', scope.$index)"
                    class="el-icon-circle-plus operatePush"></i>
                  <i v-if="scope.row.hoverRow" :disabled="disabled" @click.stop="row('del', scope.$index)"
                    class="el-icon-remove operateDel"></i>
                  <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column label="商品编码" width="180" align="center" prop="goodsNo">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>商品编码</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.$index + '.goodsNo'" :rules="rules[`details.goodsNo`]">
                    <SelectRemote :disabled="disabled" v-model="scope.row.goodsNo" :option="optionGoods(scope.row)" />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="商品名称" align="center" prop="goodsName" min-width="170" />
              <el-table-column label="商品条码" align="center" prop="barcode" min-width="170" />
              <el-table-column label="规格" align="center" prop="goodsSpec" width="120"><template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>规格</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.$index + '.goodsSpec'" :rules="rules[`details.goodsSpec`]">
                    <el-select v-model="scope.row.goodsSpec" :disabled="disabled" size="mini"
                      @change="unitPriceChange('goodsSpec', scope.row, $event)">
                      <el-option v-for="item in scope.row.units" :key="item.goodsSpec" :label="item.goodsSpec"
                        :value="item.goodsSpec" />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="单位" align="center" prop="unitId" min-width="110">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>单位</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.$index + '.unitId'" :rules="rules[`details.unitId`]">
                    <el-select v-model="scope.row.unitId" :disabled="disabled"
                      @change="unitPriceChange('unitId', scope.row, $event)" size="mini">
                      <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                        :value="item.unitId" />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="领料仓库" align="center" prop="outStoreId" min-width="160">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>领料仓库</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.$index + '.outStoreId'" :rules="rules[`details.outStoreId`]">
                    <SelectRemote v-model="scope.row.outStoreId" style="width: 140px"
                      :disabled="disabled || !leadMaterialForm.produceDeptId" :option="
                        $select({
                          key: 'listProduceStore',
                          option: {
                            option: {
                              remoteBody: {
                                produceDeptId: leadMaterialForm.produceDeptId,
                              },
                              disabled: disabled,
                              showItem: [
                                {
                                  storeId: scope.row.outStoreId,
                                  produceStoreName: scope.row.outStoreName,
                                },
                              ],
                            },
                          },
                        }).option
                      " />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="需求数量" align="center" prop="importUnitQty" min-width="128"
                :formatter="(v) => $syInput(1, v.importUnitQty)" />

              <el-table-column label="领用数量" align="center" min-width="130" prop="unitQty">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>领用数量</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.$index + '.unitQty'" :rules="rules[`details.unitQty`]">
                    <el-input @mousewheel.native.prevent @DOMMouseScroll.native.prevent v-model="scope.row.unitQty"
                      @keyup.native="onkeyupUnitQty(scope.row)" :disabled="disabled" size="mini" type="number"
                      maxlength="7" @input="
                        limitInputlength(scope.row.unitQty, scope.row, 'unitQty', true)
                      " :sunyunFormat="(v) => $syInput(1, v)" @click.native.stop></el-input>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column label="单位成本价" align="center" prop="unitPrice" min-width="110"
                :formatter="(v) => $syInput(2, v.unitPrice)" />
              <el-table-column label="单位成本金额" align="center" prop="unitMoney" min-width="110"
                :formatter="(v) => $syInput(3, v.unitMoney)" />
              <el-table-column label="备注" align="center" prop="remark" min-width="130">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.remark" :disabled="disabled" size="mini" maxlength="80"
                    @click.native.stop></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </el-form>
</template>

<script>
import { getNewDate } from "@/utils/newDate"; //单据日期
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import { getBillNo } from "@/api/codeRule"; //单据号
import {
  leadMaterialAddAPI,
  leadMaterialDetailAPI,
  leadMaterialUpdateAPI,
  leadMaterialUpdateStatusAPI,
} from "@/api/produce/bill/leadMaterial"; //生产领料
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import Dialog from "@/components/Dialog";
import { limitInputlength, fcount, isNumber, deepCopy } from "@/utils";
import {
  getTableSelect, //下拉框
  getSelectAssign, //赋值
  getCellMouseEnter, //显示加减
  getCellMouseLeave, //隐藏加减
} from "@/utils/orderTableSelect.js"; //表格的下拉框公共配置
import { produceDeptList } from "@/api/produce/sets/produceDept"; //生产工厂
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";

export default {
  name: "leadMaterialDetail",
  dicts: ["App_is_use", "bill_status"],
  components: {
    cardTitleCom,
    topOperatingButton,
    SelectRemote,
    SelectLocal,
    Dialog,
  },
  data() {
    return {
      isImportSource: false, //是否引入源单
      //表格遮罩
      loadingTable: false,
      tableCellLabel: "序号",
      //弹窗配置
      dialogOptions: {
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "goods" }),
      },
      //仓库数据
      storeData: [],
      showImportBill: false, //引入源单按钮
      showAdd: false, //新增按钮
      showAudit: true, //审核按钮禁用状态
      //表单禁用
      disabled: false,
      // 遮罩层
      loading: false,
      //旧的表单
      oldForm: {},
      // 表单参数
      leadMaterialForm: {
        billDate: "", //单据日期
        billNo: undefined, //单据编号
        billId: undefined, //单据id
        billStatus: "", //单据审核状态
        //单据表格-订单明细
        details: [{}],
      },
      //调出仓库id
      ids: [],
      //调出仓库多选
      multiple: true,
      // 表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则配置单据类型",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [
          {
            required: true,
            message: "单据日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        produceDeptId: [
          {
            required: true,
            message: "领料工厂不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.goodsNo": [
          {
            required: true,
            message: "商品编码不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.goodsSpec": [
          {
            required: true,
            message: "规格不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.unitId": [
          {
            required: true,
            message: "单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.outStoreId": [
          {
            required: true,
            message: "领料仓库不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.unitQty": [
          {
            required: true,
            message: "领用数量不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      vivwH: 0, //页面高度
      topOperatingH: 0, //顶部按钮高度
      basicH: 0, //基本信息
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 重置数据
      vm.reset();
      //初始化数据
      await vm.getInitializeData();
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        if (vm.$route.query.billId) {
          //获取单据详情
          const { data } = await leadMaterialDetailAPI(vm.$route.query.billId);
          //单据赋值
          vm.leadMaterialForm = data;
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.leadMaterialForm));
        }
      } else if (vm.$route.query.type === "importLeadMaterial") {
        await vm.getOrderBillNo();
        vm.isImportSource = true;
        //缓存工厂
        vm.leadMaterialForm.produceDeptId = Number(vm.$route.query.produceDeptId);
        //生产单号
        vm.leadMaterialForm.sourceBillNo = vm.$route.query.sourceBillNo;
        if (vm.$route.query.sourceGoodsList) {
          vm.$set(
            vm.leadMaterialForm,
            "details",
            JSON.parse(vm.$route.query.sourceGoodsList)
          );
          vm.$set(
            vm.leadMaterialForm,
            "sourceBillId",
            vm.leadMaterialForm[0]?.sourceBillId
          );
          if (vm.leadMaterialForm.details.length == 0) {
            vm.leadMaterialForm.details = [{}];
          }
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.leadMaterialForm));
      } else if (vm.$route.query.type === "matchMaterialOrder") {
        //单据赋值
        vm.leadMaterialForm = JSON.parse(vm.$route.query.orderInfo);
        await vm.$nextTick()
        await vm.getOrderBillNo();
        //原始单据赋值
        vm.oldForm = JSON.parse(JSON.stringify(vm.leadMaterialForm));
      } else {
        await vm.getOrderBillNo();
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.leadMaterialForm = vm.oldForm;
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.leadMaterialForm));
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.leadMaterialForm.billId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.leadMaterialForm));
    }
    next();
  },
  beforeDestroy() {
    // 在页面销毁前执行的逻辑
    this.oldForm = {};
  },
  watch: {
    //侦听单据状态
    "leadMaterialForm.billStatus": {
      handler(newVal) {
        if (this.leadMaterialForm.billStatus == "2") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = true;

          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
        } else if (this.leadMaterialForm.billStatus == "3") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = true;
          //禁止全部输入按钮
          this.disabled = true;
        } else if (this.leadMaterialForm.billStatus == "0") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = false;

          //恢复全部输入
          this.disabled = false;
        } else if (this.leadMaterialForm.billStatus == "4") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = false;

          //恢复全部输入
          this.disabled = false;
        } else if (this.leadMaterialForm.billStatus == "") {
          //引入源单按钮
          this.showImportBill = true;
          //新增按钮
          this.showAdd = false;

          //禁用审核按钮
          this.showAudit = true;
          //恢复全部输入
          this.disabled = false;
        }
      },
      immediate: true,
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout();
    });
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.topOperatingH - this.basicH - 62;
    },
    //商品下拉框配置项
    optionGoods() {
      var that = this;
      return function (row) {
        return getTableSelect(
          {
            goodsTypes: [2, 3],
            billDetailItems: this.leadMaterialForm.details,
            getMultipleGoods: this.getGoodS,
            row,
          },
          that
        );
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.topOperatingH = document.getElementById("topOperatingButton")?.clientHeight;
      this.basicH = document.getElementById("basicInformation")?.clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // 在页面销毁前执行的逻辑
    this.oldForm = {};
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (
        this.leadMaterialForm.billStatus == "0" ||
        this.leadMaterialForm.billStatus == ""
      ) {
        this.leadMaterialForm.details = getCellMouseEnter(
          row,
          this.leadMaterialForm.details
        );
        this.tableCellLabel = "操作";
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      this.leadMaterialForm.details = getCellMouseLeave(this.leadMaterialForm.details);
      this.tableCellLabel = "序号";
    },
    limitInputlength,
    changeProduceGroupChange(v) {
      this.leadMaterialForm.details.forEach((item) => {
        item.outStoreId = undefined;
      });
    },
    //打开弹窗
    getDistribution(val) {
      if (this.multiple) return this.$message.warning("请选择商品");
      this.dialogOptions.title = "选择仓库";
      this.dialogOptions.formData = this.$dialog({
        key: "produceStore",
        option: {
          table: {
            ...this.$dialog({ key: "produceStore" }).table,
            radioSelect: true,
            mutiSelect: false,
          },
        },
      });
      this.dialogOptions.formData.table.pagination = {
        ...this.dialogOptions.formData.table.pagination,
        produceDeptId: this.leadMaterialForm.produceDeptId,
      };
      this.dialogOptions.show = true;
    },

    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          this.chooseOutStoreIdChange(
            this.dialogOptions.formData.table.check[0].storeId,
            this.dialogOptions.formData.table.check[0].produceStoreName
          );
          break;
        default:
          break;
      }
    },
    //批量修改仓库
    chooseOutStoreIdChange(chooseOutStoreId, produceStoreName) {
      try {
        this.loadingTable = true;
        const promises = this.leadMaterialForm.details.map(async (item) => {
          if (this.ids.includes(item.goodsId)) {
            this.$set(item, "outStoreId", chooseOutStoreId);
            this.$set(item, "outStoreName", produceStoreName);
          }
        });
        Promise.all(promises).then(() => {
          this.loadingTable = false;
        });
      } catch (error) {
        this.loadingTable = false;
      }
    },
    //当前行
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      if (!this.disabled) {
        this.$refs.multipleTable.toggleRowSelection(row);
      }
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection
        .map((item) => item.goodsId)
        .filter((ite) => typeof ite !== "undefined"); //单据id
      this.multiple = !this.ids.length;
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.topOperatingH = document.getElementById("topOperatingButton")?.clientHeight;
      this.basicH = document.getElementById("basicInformation")?.clientHeight;
    },
    //计算
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const other = ["importUnitQty", "unitQty", "unitMoney"];
        const [key, orderStoreId] = column.property?.split?.(".") || [];
        if (other.includes(column.property) || other.includes(key)) {
          let values = data.map((x) => {
            let num = Number(x[column.property] || 0);
            if (orderStoreId) {
              num = Number(
                x.storeItemCache?.find?.((x) => x.orderStoreId === orderStoreId)?.[key] ||
                0
              );
            }
            return num;
          });
          sums[index] = values.reduce(
            (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
            0
          );
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/produce/bill/leadMaterial" });
    },
    //新增按钮
    async handleAdd() {
      this.loading = true;
      this.reset();
      await this.getOrderBillNo();
      await this.getInitializeData();
      this.oldForm = JSON.parse(JSON.stringify(this.leadMaterialForm));
      this.loading = false;
    },

    //多级单位换算
    unitPriceChange(name, row, value) {
      let obj = row.units.find((v) => v[name] == value);
      if (name == "goodsSpec") {
        this.$set(row, "unitId", obj.unitId);
      } else if (name == "unitId") {
        this.$set(row, "goodsSpec", obj.goodsSpec);
      }
      if (row.unitPrice && row.unitQty) {
        this.$set(row, "unitPrice", obj.unitPrice);
        let unitMoney = row.unitQty * row.unitPrice;
        this.$set(row, "unitMoney", unitMoney);
      } else {
        this.$set(row, "unitMoney", 0);
      }
    },
    //领用数量
    onkeyupUnitQty(row) {
      if (row.unitPrice && row.unitQty) {
        let unitMoney = row.unitQty * row.unitPrice;
        unitMoney = this.formatNumber(unitMoney);
        this.$set(row, "unitMoney", unitMoney);
      } else {
        this.$set(row, "unitMoney", 0);
      }
    },

    // 表单重置
    reset() {
      this.isImportSource = false; //是否引入源单
      //新增按钮
      this.showAdd = false;
      //保存禁用
      this.disabled = false;
      this.leadMaterialForm = {
        billDate: "", //单据日期
        billNo: undefined, //单据编号
        billStatus: "", //单据审核状态
        //单据表格-订单明细
        details: [{}],
      };
      this.resetForm("form");
    },
    //单据编号
    async getOrderBillNo() {
      this.leadMaterialForm.billNo = await getBillNo(170202);
    },
    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.leadMaterialForm.billStatus != "0") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          await this.submitForm(true, "audit");
          const obj = {
            billIds: [this.leadMaterialForm.billId],
            billStatus: "2",
            billType: "170202",
          };
          //发送审核api
          const { data } = await leadMaterialUpdateStatusAPI(obj);
          this.leadMaterialForm = data;
          // 弹出提示
          // this.$message.success('审核成功')
        } else if (name === "反审核") {
          if (this.leadMaterialForm.billStatus != "2") {
            this.$message.error("此单据不是已审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.leadMaterialForm.billId],
            billStatus: "0",
            billType: "170202",
          };
          //发送反审核api
          const { data } = await leadMaterialUpdateStatusAPI(obj);
          this.leadMaterialForm = data;
          // 弹出提示
          this.$message.success("反审核成功");
        }
      } catch { }
    },
    /** 提交按钮 */
    async submitForm(isBool, audit) {
      const valid = await this.$refs["form"].validate();
      try {
        this.loading = true;
        if (valid) {
          if (this.leadMaterialForm.billId) {
            const res = await leadMaterialUpdateAPI(this.leadMaterialForm);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.leadMaterialForm = res.data;
              this.$modal.msgSuccess(audit ? "审核成功" : "修改单据成功");
            } else {
              await this.reset();
              await this.getOrderBillNo();
              this.$modal.msgSuccess("保存并新增单据成功");
            }
          } else {
            const { data } = await leadMaterialAddAPI(this.leadMaterialForm);

            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.leadMaterialForm = data;
              this.$modal.msgSuccess(audit ? "审核成功" : "新增单据成功");
            } else {
              await this.reset();
              await this.getOrderBillNo();
              this.$modal.msgSuccess("保存并修改单据成功");
            }
          }
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    //保存并新增单据
    async addBill() {
      this.$confirm("是否保存并新增单据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(async () => {
          try {
            this.loading = true;
            await this.submitForm(false);
          } finally {
            this.loading = false;
          }
        })
        .catch();
    },
    //引入源单
    getImportOrder() {
      if (!this.leadMaterialForm.produceDeptId)
        return this.$message.error("领料工厂不能为空");
      this.$router.push({
        name: "leadMaterialImport",
        query: {
          produceDeptId: this.leadMaterialForm?.produceDeptId,
          type: "leadMaterialImport",
        },
      });
    },
    //选中的商品
    getGoodS(rows) {
      this.loadingTable = true;
      rows.forEach((v) => {
        this.$set(v, "unitQty", 0);
        this.$set(v, "unitPrice", v.purPrice);
        this.$set(v, "unitMoney", v.unitQty * v.unitPrice);
        v.hoverRow = false;
        v.remark = undefined;
      });
      this.leadMaterialForm.details = getSelectAssign(
        rows,
        this.leadMaterialForm.details
      );
      this.loadingTable = false;
    },
    //表格增加/减少一行方法
    row(name, index) {
      if (name === "push") {
        this.leadMaterialForm.details.splice(index + 1, 0, {
          goodsNo: "",
          hoverRow: false,
        });
      } else {

        if (this.leadMaterialForm.details.length <= 1) {
          this.leadMaterialForm.details = [{
            goodsNo: "",
            hoverRow: false,
          }]
        } else {
          this.leadMaterialForm.details.splice(index, 1);
        }
      }
    },

    //初始化页面数据
    async getInitializeData() {
      this.loading = true;
      //获取单据日期
      this.leadMaterialForm.billDate = getNewDate();
      const res = await produceDeptList({ produceDeptNo: "0000" });
      if (res.rows[0]?.produceDeptId) {
        //默认生产工厂
        this.$set(this.leadMaterialForm, "produceDeptId", res.rows[0]?.produceDeptId);
        //默认生产工厂名称
        this.$set(this.leadMaterialForm, "produceDeptName", res.rows[0]?.produceDeptName);
        // this.changeProduceGroupChange(res.rows[0]?.produceDeptId)
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .headGoodsTable {
    //商品表格卡片
    .goodsMessage {
      padding: 6px 10px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
      ::v-deep .el-form-item {
        margin-bottom: 0;
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 20px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
